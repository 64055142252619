<template>
  <PagesIndexBlocksLayoutComponent
    :label="label"
    :description="description"
    :linkLabel="linkLabel"
    routeName="news"
  >
    <LayoutSliderComponent
      v-if="childData?.data?.length"
      :entries="childData?.data"
      :component="SharedThumbnailNewsComponent"
      linkType="dynamic"
    />
  </PagesIndexBlocksLayoutComponent>
</template>

<script setup>
import { SharedThumbnailNewsComponent } from '#components'

const props = defineProps({
  block: Object
})

const label = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.labels))

const linkLabel = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.link_labels))

const description = computed(() => i18nGetLocalizedString(props?.block?.descriptions, 'i18n_richtext_en'))

// 2
const query = {...baseRouteGetPublicationStateQuery(), pageSize: 6}
const { data: childData, execute } = await useLazyAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryNewsEntryThumbnails(query) } } )
})
</script>

<style module>
.root {
  padding: calc(var(--unit--vertical) * 3) var(--unit--horizontal);
}
</style>
