<template>
  <PagesIndexBlocksLayoutComponent
    v-if="childData?.data?.length"
    :label="label"
    :linkLabel="linkLabel"
    :description="description"
    routeName="focus-week"
    :class="$style.root"
  >
    <ul :class="$style.list">
      <li
        v-for="entry in childData?.data"
        :key="entry.id"
        :class="$style.item"
        :data-dynamic-layout="childData?.data?.length > 3 ? '' : null"
      >
        <SharedThumbnailFocusWeekComponent
          :data="entry"
          linkType="dynamic"
        />
      </li>
    </ul>
  </PagesIndexBlocksLayoutComponent>
</template>

<script setup>
const props = defineProps({
  block: Object
})

const label = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.labels))

const linkLabel = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.link_labels))

const description = computed(() => i18nGetLocalizedString(props?.block?.descriptions, 'i18n_richtext_en'))

// 2
const childDataFilters = computed(() => {
  return {
    date_end: { $gte: new Date().toISOString() }
  }
})

const query = {...baseRouteGetPublicationStateQuery(), filters: childDataFilters.value, sort: ['date_start:asc', 'date_start:asc'], pageSize: 3 }

const { data: childData, execute } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryFocusWeekEntryThumbnails(query) } } )
}, { immediate: false })

onMounted(() => {
  execute()
})
</script>

<style module lang="scss">
.root {
}

.heading {
  composes: reset-heading font-size-medium from global;
}

.list {
  composes: reset-list from global;
  display: flex;
  flex-wrap: wrap;

  padding: 0 calc(var(--unit--horizontal) / 2);
  gap: 0;

  margin: calc(var(--unit--vertical) * -1) 0;

  @media (max-width: $breakpoint-s) {
    margin: calc(var(--unit--vertical) * -.5) 0;
  }
}

.item {
  padding: calc(var(--unit--vertical) * 1) calc(var(--unit--horizontal) / 2);
}

.item:not([data-dynamic-layout]) {
  width: calc(100% / 3 );
  @media (max-width: $breakpoint-m) {
    width: 50%;
  }

  @media (max-width: $breakpoint-s) {
    padding: calc(var(--unit--vertical) * .5) calc(var(--unit--horizontal) / 2);
    width: 100%;
  }
}

.item[data-dynamic-layout] {
  width: calc(100% / 3 );
  @media (max-width: $breakpoint-m) {
    width: 50%;
  }

  @media (max-width: $breakpoint-s) {
    padding: calc(var(--unit--vertical) * .5) calc(var(--unit--horizontal) / 2);
    width: 100%;
  }
}

.item[data-dynamic-layout]:nth-child(-n+2) {
  width: 50%;

  @media (max-width: $breakpoint-s) {
    width: 100%;
  }
}
</style>
