<template>
  <PagesIndexBlocksLayoutComponent
    v-if="isDesktop"
    :label="label"
    :description="description"
    :linkLabel="linkLabel"
    routeName="cities"
    :showMobileLink="false"
  >
    <MapUserEngagementContainerComponent
      :class="$style.container"
    >
      <LazyMapComponent :class="$style.map" />
    </MapUserEngagementContainerComponent>
  </PagesIndexBlocksLayoutComponent>
</template>

<script setup>
const { isDesktop } = useDevice();

const props = defineProps({
  block: Object
})

const label = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.labels))

const linkLabel = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.link_labels))

const description = computed(() => i18nGetLocalizedString(props?.block?.descriptions, 'i18n_richtext_en'))
</script>

<style module>
.container {
  margin: 0 var(--unit--horizontal);
}
</style>
